import React, { useEffect, useMemo } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import qs from 'query-string'
import cn from 'classnames'

import { AuthFlow } from 'components/auth-flow'
import { StatusMessage } from 'components/status-message'

import useAuth from 'lib/hooks/useAuth'
import useTracking from 'lib/hooks/useTracking'
import useRouteMatch from 'lib/hooks/useRouteMatch'
import usePageTrackEvent from 'lib/hooks/usePageTrackEvent'
import { useSocialOnboard } from 'lib/hooks/socialAuth'
import { useReferralInAuth } from 'lib/hooks/useReferralInAuth'

import { buildPath, isServer } from 'lib/utils'
import { NextPageWithLayout } from 'lib/@Types/layout'

import { EVENTS } from 'lib/constants/events'
import { LOGIN_ROUTE, SIGNUP_ROUTE } from 'lib/constants/routes'

import s from './styles.module.scss'

const AuthPage: NextPageWithLayout = () => {
  const { authInProgress } = useAuth()
  const { onboardUser, error: onboardingError } = useSocialOnboard()
  const { getPreAuthReferralCode, clearPreAuthReferralCode } = useReferralInAuth()

  const router = useRouter()
  const { t } = useTranslation()
  const isLogin = Boolean(useRouteMatch(LOGIN_ROUTE))
  const isSignup = Boolean(useRouteMatch(SIGNUP_ROUTE))

  const { redirectUrl } = isServer ? router.query : qs.parse(window.location.search)

  const { trackEvent } = useTracking({
    pageName: isLogin ? EVENTS.LOGIN : EVENTS.SIGNUP,
    pageMainAttributeType: 'auth',
    pageViewedDeps: [isLogin],
    additionalParams: { source: redirectUrl },
  })
  usePageTrackEvent({ trackEvent })

  // onboard user if redirected from social auth
  // onboard user is only handled in page view, not in modal view
  useEffect(() => {
    const params = qs.parse(window.location.search)
    const oAuthState = params.state as string | undefined
    const oAuthProvider = (params.oAuthProvider || params.loginType) as OauthProvider | undefined
    const oAuthCode = (params.code || params.cvsKey) as string | undefined
    const extCustomerId = params.customerid as string | undefined
    const referralCode = getPreAuthReferralCode()

    clearPreAuthReferralCode()

    if (oAuthCode && oAuthProvider) {
      onboardUser({
        state: oAuthState ?? '',
        code: oAuthCode,
        provider: oAuthProvider,
        extCustomerId,
        referralCode,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSwitchScreen = (nextScreen: AuthScreens) => {
    const searchParams = qs.parse(window.location.search)

    if (nextScreen === 'login' && isSignup) {
      router.push(buildPath(LOGIN_ROUTE, {}, searchParams))
    }
    if (nextScreen === 'signup' && isLogin) {
      router.push(buildPath(SIGNUP_ROUTE, {}, searchParams))
    }
  }
  // eslint-disable-next-line no-confusing-arrow
  const initialScreen = useMemo(() => (isLogin ? 'login' : 'signup'), [isLogin])

  return (
    <>
      <Head>
        <title>{isLogin ? t('t.logIn') : t('t.signUp')}</title>
      </Head>

      <div className={s.card}>
        <div className={cn(s.content, { [s.loading]: authInProgress })}>
          {/* TODO: this should be changed to a snackbar once it is available */}
          {onboardingError && <StatusMessage type="error">{onboardingError}</StatusMessage>}

          <AuthFlow
            key={initialScreen}
            custom={null}
            intialScreen={initialScreen}
            beforeScreenSwitch={handleSwitchScreen}
            trackEvent={trackEvent}
          />
        </div>
      </div>
    </>
  )
}

export { AuthPage }
