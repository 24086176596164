import { GetServerSideProps } from 'next'
import { ReactElement } from 'react'

import MainLayout from 'components/layout/main-layout'

import { AuthPage } from 'page-modules/auth'

import { serverSideTranslations } from 'lib/utils/server'

export default AuthPage

AuthPage.getLayout = function getLayout(page: ReactElement) {
  return (
    <MainLayout noFooter={false} hasLangCurrSelector={false}>
      <MainLayout.Content className="l-main--no-pad-bottom">{page}</MainLayout.Content>
    </MainLayout>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, 'LOGIN')),
    },
  }
}
